'use client';
import { useRouter } from 'next/navigation';
import { MouseEvent, useContext, useEffect, useMemo, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { InventoryTypeBadge } from '@/components/Inventory/Badges/InventoryTypeBadge';
import { defaultImageUrl } from '@/constants/inventory';
import { InventoryContext } from '@/contexts/InventoryContext';
import { InventoryFormContext } from '@/contexts/InventoryFormContext';
import { LayoutContext } from '@/contexts/LayoutContext';
import { getFavoritesChannel } from '@/tools/broadcast';
import { getImageUrl, getListPriceLabel, isNewUnit } from '@/tools/inventory';
import { isMobile } from '@/tools/views';
import { IMessageFavorites, MessageFavoritesType } from '@/types/inventory';
import { faHeart as faHeartOutline } from '@fortawesome/free-regular-svg-icons';
import { faHashtag, faHeart as faHeartSolid } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonType } from '@lamesarv-sdk/components';
import {
  getFacetCode,
  getInventoryImage,
  getInventoryPageUrl,
  getMinimalInventory,
  navigate,
} from '@lamesarv-sdk/tools';
import { IBasicInventory, IInventory, IInventoryPriceFlag, InventoryField } from '@lamesarv-sdk/types';

import { FavoriteBadge } from '../Inventory/Badges/FavoriteBadge';
import { FlashSaleBadge } from '../Inventory/Badges/FlashSaleBadge';
import { SpecialOfferBadge } from '../Inventory/Badges/SpecialOfferBadge';

const IMAGE_WIDTH = 340;
const IMAGE_HEIGHT = 285;

export interface IInventoryItemProps {
  item: IBasicInventory;
  customImage?: string;
  sold?: boolean;
  className?: string;
}

const formatPrice = (price: number) => Math.round(price).toLocaleString(undefined, { maximumFractionDigits: 0 });

interface IGenericPricingProps {
  secondaryPrice?: {
    label: string;
    value: number;
  };
  primaryPrice?: {
    label: string;
    value: number;
  };
  displaySave?: boolean;
}

const GenericPricing = ({ secondaryPrice, primaryPrice, displaySave }: IGenericPricingProps) => {
  const bigPrice = primaryPrice || secondaryPrice;
  const smallPrice = primaryPrice && secondaryPrice;

  return (
    <div className="flex gap-2 flex-col">
      {smallPrice && (
        <span>
          <span className="text-gray-500 uppercase" data-testid="unit-list-label">
            {smallPrice.label}{' '}
          </span>
          <span className="line-through font-bold" data-testid="unit-list-price">
            ${formatPrice(smallPrice.value)}
          </span>
        </span>
      )}
      {bigPrice && (
        <div className="flex gap-2 items-center">
          <span className="text-gray-500 uppercase" data-testid="unit-sale-label">
            {bigPrice.label}
          </span>
          <span
            className={twMerge(
              'font-bold text-[1.75rem] leading-8',
              !displaySave && 'line-through',
              !displaySave && !!smallPrice && 'text-[#9B0505]',
            )}
            data-testid="unit-sale-price"
          >
            ${formatPrice(bigPrice.value)}
          </span>
        </div>
      )}
      {displaySave && smallPrice && bigPrice && bigPrice.value !== smallPrice.value && (
        <span className="text-[#76881D] font-bold text-lg">
          Save ${formatPrice(smallPrice.value - bigPrice.value)} (
          {(((smallPrice.value - bigPrice.value) / smallPrice.value) * 100).toFixed(0)}% off)
        </span>
      )}
    </div>
  );
};

const UsedPricing = ({ item }: { item: IBasicInventory }) => (
  <GenericPricing
    displaySave
    primaryPrice={!!item[InventoryField.priceSale] && { label: 'SALE PRICE', value: item[InventoryField.priceSale] }}
    secondaryPrice={
      !!item[InventoryField.priceList] && { label: getListPriceLabel(item), value: item[InventoryField.priceList] }
    }
  />
);

const NewPricing = ({ item }: { item: IBasicInventory }) => {
  const { context: layoutContext } = useContext(LayoutContext);
  const router = useRouter();
  const { openMobileInquire } = useContext(InventoryFormContext);

  const salePrice =
    item[InventoryField.priceFlag] === IInventoryPriceFlag.windowPrice ||
    item[InventoryField.priceFlag] === IInventoryPriceFlag.codedCost
      ? item[InventoryField.priceSale]
      : item[InventoryField.priceWas];

  const handleClickCTA = (e: MouseEvent<HTMLSpanElement>) => {
    e.preventDefault();

    const inventoryPageUrl = getInventoryPageUrl(item);

    if (!isMobile()) {
      navigate(inventoryPageUrl + '#pdpform');
      return;
    }

    router.prefetch(inventoryPageUrl);
    openMobileInquire(item);
  };

  const displaySave = item[InventoryField.priceFlag] === IInventoryPriceFlag.windowPrice;

  return (
    <>
      <GenericPricing
        displaySave={displaySave}
        primaryPrice={!!salePrice && { label: 'SALE PRICE', value: salePrice }}
        secondaryPrice={
          !!item[InventoryField.priceList] && { label: getListPriceLabel(item), value: item[InventoryField.priceList] }
        }
      />

      {!!item[InventoryField.priceList] && !displaySave && (
        <span
          className="mt-2 cursor-pointer rounded-sm bg-[#1E1E1E] border border-transparent group-hover:border-black group-hover:bg-white group-hover:text-black px-4 py-3 text-center text-sm font-bold uppercase text-white text-opacity-90 shadow-sm group-hover:shadow-md"
          onClick={handleClickCTA}
          dangerouslySetInnerHTML={{ __html: layoutContext.noPriceButtonContent || '' }}
        />
      )}
    </>
  );
};

export const InventoryItem = ({ item, customImage, sold, className }: IInventoryItemProps) => {
  const { context, favoriteSelectInventory, favoriteUnselectInventory } = useContext(InventoryContext);
  const [isFavorite, setIsFavorite] = useState<boolean>(false);

  const inventoryImage = useMemo(() => {
    if (customImage) return customImage;
    if (item[InventoryField.imageCloudinaryIds]?.length) {
      return getImageUrl(item[InventoryField.imageCloudinaryIds][0], IMAGE_WIDTH, IMAGE_HEIGHT);
    }
    return getInventoryImage({ item, defaultImageUrl });
  }, [item, defaultImageUrl]);

  const handleFavorite = (e: React.ChangeEvent<HTMLInputElement>) => {
    const channelFavorites = getFavoritesChannel();
    if (e.target.checked) {
      const minimalItem = getMinimalInventory(item as IInventory, defaultImageUrl, inventoryImage);
      const newFavorites = favoriteSelectInventory(minimalItem, true);
      channelFavorites.postMessage({
        type: MessageFavoritesType.favoriteSelectInventory,
        id: item[InventoryField.id],
        quantity: newFavorites.length,
      } as IMessageFavorites);
    } else {
      const newFavorites = favoriteUnselectInventory(item[InventoryField.id], true);
      channelFavorites.postMessage({
        type: MessageFavoritesType.favoriteUnselectInventory,
        id: item[InventoryField.id],
        quantity: newFavorites.length,
      } as IMessageFavorites);
    }
  };

  const handleSimilarUnits = () => {
    navigate(`/search?model=${getFacetCode(item[InventoryField.model])}`);
  };

  useEffect(() => {
    const channel = getFavoritesChannel();

    channel.onmessage = (message: IMessageFavorites) => {
      if (message.id === item[InventoryField.id]) {
        setIsFavorite(message.type === MessageFavoritesType.favoriteSelectInventory);
      }
    };

    return () => {
      channel.close();
    };
  }, [item]);

  useEffect(() => {
    setIsFavorite(!!context?.inventoryFavorites?.find((favorite) => favorite.id === item[InventoryField.id]));
  }, [context.inventoryFavorites, item]);

  return (
    <div className={twMerge('group xs:mx-2.5 pb-2 h-full', className)} data-testid="inventory-unit">
      <a
        href={sold ? undefined : getInventoryPageUrl(item)}
        className="flex flex-col h-full shadow-md hover:shadow-[0px_2px_10px_0px_rgba(0,0,0,0.1),-4px_2px_20px_1px_rgba(0,0,0,0.1)] m-3 xs:m-4 md:m-0"
      >
        <div className="relative">
          <img
            src={inventoryImage}
            alt={item[InventoryField.title]}
            className="w-full object-cover group-hover:opacity-90"
          />
          {sold && (
            <div className="absolute inset-0 bg-neutral-400 bg-opacity-70">
              <div className="absolute w-full top-1/2 -translate-y-1/2">
                <div className="py-1 mb-2 bg-neutral-400 bg-opacity-70 sm:text-md text-center text-white font-bold uppercase">
                  Currently not available
                </div>
                <Button
                  type={ButtonType.primary}
                  title="Find similar units"
                  className="py-2 px-4 mx-auto uppercase"
                  onClick={() => handleSimilarUnits()}
                />
              </div>
            </div>
          )}

          <InventoryTypeBadge
            inventoryType={item[InventoryField.inventoryType]}
            className={twMerge('z-10 absolute -bottom-3 left-4', sold && 'bg-neutral-500')}
          />
          {!sold && (item[InventoryField.inFlashSale] || item[InventoryField.special]) && (
            <div className="absolute bottom-4 left-4 flex flex-col items-start gap-2">
              {item[InventoryField.special] && <SpecialOfferBadge />}

              {item[InventoryField.inFlashSale] && <FlashSaleBadge />}
            </div>
          )}
          <div className="absolute top-2 left-2 flex items-center gap-1 z-10">
            {!sold && (
              <label
                className={twMerge(
                  'group/favorites bg-white bg-opacity-70 p-2 border-2 leading-none cursor-pointer rounded-full transition-all',
                  isFavorite ? 'border-red-500' : 'border-neutral-700',
                )}
                data-testid="favorite-btn"
              >
                <input type="checkbox" className="hidden" onChange={handleFavorite} checked={isFavorite} />
                <FontAwesomeIcon
                  icon={isFavorite ? faHeartSolid : faHeartOutline}
                  className={twMerge(
                    'w-4 h-4 leading-none',
                    isFavorite ? 'text-red-500' : 'group-hover/favorites:text-red-500',
                  )}
                />
              </label>
            )}
            <FavoriteBadge favorites={item[InventoryField.favorites] || 0} />
          </div>
        </div>
        <div className="relative flex flex-col flex-1 bg-white group-hover:bg-blue-50 justify-between">
          <div className="flex flex-col min-h-[4.25rem] px-4 pt-5">
            <h3 className="font-bold uppercase" data-testid="unit-title">
              {item[InventoryField.title]}
            </h3>
            <div className="flex items-center gap-1 text-neutral-500 mt-1">
              <FontAwesomeIcon icon={faHashtag} className="w-4 h-4" />
              <span className="text-xs" data-testid="unit-stock-number">
                Stock Number: {item[InventoryField.stockNumber]}
              </span>
            </div>
            <div className="flex items-center gap-1 text-neutral-500 mt-1">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4">
                <path
                  fillRule="evenodd"
                  d="m11.54 22.351.07.04.028.016a.76.76 0 0 0 .723 0l.028-.015.071-.041a16.975 16.975 0 0 0 1.144-.742 19.58 19.58 0 0 0 2.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 0 0-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 0 0 2.682 2.282 16.975 16.975 0 0 0 1.145.742ZM12 13.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="text-xs" data-testid="unit-location">
                {item[InventoryField.location]}
              </span>
            </div>
          </div>
          <div className="flex flex-col flex-1 justify-between">
            <div className={twMerge('mt-4 flex gap-2 flex-col px-4 flex-1 justify-between', sold && 'blur-sm')}>
              {isNewUnit(item) ? <NewPricing item={item} /> : <UsedPricing item={item} />}
            </div>
            <div className="mt-4 border-[#D9D9D9] flex border-t justify-evenly">
              <span
                className="font-bold uppercase my-4 text-xs whitespace-break-spaces mx-2 xs:mx-3"
                data-testid="unit-type"
              >
                {item[InventoryField.body]} - {item[InventoryField.fuelType]}
              </span>
              <figure className="border-l border-[#D9D9D9]" />
              <span className="uppercase my-4 text-xs mx-2 xs:mx-3" data-testid="unit-slideouts">
                <span className={twMerge(!!item[InventoryField.slideOutsCount] ? 'font-bold' : 'text-black/60')}>
                  {item[InventoryField.slideOutsCount] || 'NO'}
                </span>
                <span className="text-black/60"> SLIDEOUT{item[InventoryField.slideOutsCount] === 1 ? '' : 'S'}</span>
              </span>
            </div>
          </div>
          {sold && <div className="absolute inset-0 bg-white bg-opacity-70" />}
        </div>
      </a>
    </div>
  );
};
