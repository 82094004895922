import { twMerge } from 'tailwind-merge';

interface FlashSaleBadgeProps {
  className?: string;
}

export const FlashSaleBadge = ({ className }: FlashSaleBadgeProps) => (
  <div
    className={twMerge(
      'relative py-1 px-2 text-xs text-center text-white rounded uppercase font-semibold bg-red-500 flex-shrink inline-flex items-center h-6',
      className,
    )}
    data-testid="unit-flash-sale-badge"
  >
    FLASH
    <div className="relative flex items-center justify-center mx-1 w-3 h-6 overflow-visible">
      <svg
        id="Layer_1"
        className="absolute h-8 -top-1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 12 24"
      >
        <polygon
          className="fill-white stroke-0"
          points="2.96 0 0 12.96 5.08 12.96 1.57 24 12 8.63 6.77 8.63 10.21 0 2.96 0"
        />
      </svg>
    </div>
    SALE
  </div>
);
