import { twMerge } from 'tailwind-merge';

interface SpecialOfferBadgeProps {
  className?: string;
}

export const SpecialOfferBadge = ({ className }: SpecialOfferBadgeProps) => (
  <div
    className={twMerge(
      'relative py-1 px-2 text-xs text-center text-white rounded uppercase font-semibold bg-[#76881D] flex-shrink inline-flex items-center h-6',
      className,
    )}
    data-testid="unit-special-offer-badge"
  >
    Special
    <div className="relative flex items-center justify-center mx-1 w-3 h-6 overflow-visible">
      <svg
        id="Layer_1"
        className="absolute h-6 top-0"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 12 24"
      >
        <path
          className="fill-white stroke-0"
          d="M6.49,24h-.52c-.41,0-.54-.17-.54-.67v-1.79c-1.7-.03-3.5-.22-4.79-.81-.41-.2-.57-.42-.57-.95-.08-.84-.08-3.31-.08-3.34,0-.53.28-.76.7-.76h1.26c.46,0,.59.22.64.7l.15,1.68c.88.25,1.7.39,2.68.39v-4.77l-1.16-.31c-2.52-.67-3.91-2.27-3.91-5.36S1.73,2.83,5.43,2.47V.7c0-.42.18-.7.57-.7h.49c.39,0,.49.22.49.7v1.71c1.18.03,2.83.22,3.86.56.39.14.59.45.59.93v3.39c0,.5-.28.64-.7.64h-1.26c-.44,0-.62-.25-.64-.67l-.1-1.51c-.64-.11-1.24-.17-1.75-.2v4.71l.9.25c3.04.84,4.12,2.05,4.12,5.27s-1.31,5.3-5.02,5.66v1.88c0,.39-.08.67-.49.67ZM5.43,9.84v-4.23c-1.26.22-1.6,1.01-1.6,2.1,0,.87.18,1.63,1.6,2.13ZM6.98,14.1v4.23c1.34-.31,1.57-1.21,1.57-2.1,0-1.09-.23-1.65-1.57-2.13Z"
        />
      </svg>
    </div>
    Offer
  </div>
);
