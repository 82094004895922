import { twMerge } from 'tailwind-merge';

import { Badge } from '@lamesarv-sdk/components';

import { PropsWithClasses } from '@lamesarv-sdk/types';
import { InventoryType, getInventoryType } from '@/types/inventory';

interface IInventoryTypeBadgeProps extends PropsWithClasses {
  inventoryType: string;
}

export const InventoryTypeBadge = ({ inventoryType, className }: IInventoryTypeBadgeProps) => (
  <Badge
    className={twMerge(
      'text-white text-center rounded uppercase',
      inventoryType === InventoryType.new ? 'bg-[#76881D]' : 'bg-[#0097A9]',
      className,
    )}
    dataTestId={inventoryType}
  >
    {getInventoryType(inventoryType)}
  </Badge>
);
