import { twMerge } from 'tailwind-merge';

import { getFavoritesLabel } from '@/tools/views';

import { Badge } from '@lamesarv-sdk/components';

import { PropsWithClasses } from '@lamesarv-sdk/types';

interface IFavoriteBadgeProps extends PropsWithClasses {
  favorites: number;
}

export const FavoriteBadge = ({ favorites, className }: IFavoriteBadgeProps) => {
  return favorites > 0 ? (
    <Badge
      className={twMerge('text-white text-center rounded uppercase bg-red-500', className)}
      data-testid={`favorites-${favorites}`}
    >
      {getFavoritesLabel(favorites)}
    </Badge>
  ) : null;
};
