/*
  ToDO: Temporal implementation, should be replaced by the dynamic mapping
  from the CMS in the shape of: field, value_current, value_override
*/

export enum InventoryType {
  new = 'New',
  used = 'Used',
}

export const InventoryTypeMap: Record<string, string> = {
  New: 'New',
  Used: 'Preowned',
};

export const getInventoryType = (type: string): string => {
  return InventoryTypeMap[type] || type;
};

export enum MessageFavoritesType {
  favoriteSelectInventory = 'favoriteSelectInventory',
  favoriteUnselectInventory = 'favoriteUnselectInventory',
}

export interface IMessageFavorites {
  type: MessageFavoritesType;
  id?: string;
  quantity?: number;
}
